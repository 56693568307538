// Stylesheet import
import "./src/css/style.scss";
import "swiper/css/swiper.css";
import ReactPixel from "react-facebook-pixel";

if (process.env.NODE_ENV === "production") {
  ReactPixel.init("2956956984394052", null, {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  });
  ReactPixel.pageView();
}
